export const SvgSearch = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9.19238"
      cy="9.19239"
      r="5.8"
      transform="rotate(45 9.19238 9.19239)"
      stroke="#3F5DDC"
      strokeWidth="1.4"
    />
    <line
      x1="4.9502"
      y1="13.7179"
      x2="0.990397"
      y2="17.6777"
      stroke="#3F5DDC"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </svg>
);
