import styled, { css } from "styled-components";
import { defaults, colors } from "../../theme";

export const ModalLoadingWrapper = styled.div<{ $isLoading?: boolean }>`
  ${(props) =>
    props.$isLoading &&
    css`
      opacity: 0.5;
    `}
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ModalHeader = styled.div`
  padding: ${defaults.padding} ${defaults.padding} ${defaults.padding};
`;

export const ModalBody = styled.div`
  padding: 5px ${defaults.padding} 5px;
  overflow-y: scroll;
`;

export const ModalFooter = styled.div`
  padding: ${defaults.padding} ${defaults.padding} ${defaults.padding};
  margin-top: auto;
`;

export const ModalClose = styled.button<{ position?: "left" | "right" }>`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20px;
  ${(props) => (props.position === "left" ? "left: 20px;" : "right: 20px;")}
  // right: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%; // focus
  background-color: ${colors.border};
  border: 0;
  transition: box-shadow ${defaults.transition};

  &:hover {
    svg {
      opacity: 0.5;
    }
  }

  &:focus {
    box-shadow: ${defaults.focusBoxShadow};
  }

  svg {
    fill: #fff;
    display: block;
    transition: ${defaults.transitionFull};
    pointer-events: none;
  }
`;

export const ModalTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  text-align: center;
`;

export const ModalText = styled.p`
  font-family: ${defaults.fontFamily};
  line-height: 1.75;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.text};
  margin: 0 0 30px;
  text-align: center;
  word-break: break-word;

  & + & {
    margin-top: -12px;
  }
`;
