import styled from "styled-components";
import { colors, defaults } from "theme";

export const Container = styled.div`
  // background-color: ${colors.field};
  margin: 0 0 ${defaults.margin} 0;
  padding: ${defaults.containerPadding};
  border-radius: ${defaults.borderRadius};
`;

export const Title = styled.h2`
  text-align: center;
  margin: 0 0 20px 0;
  color: ${colors.title};
  font-size: ${defaults.headingSize};
  font-weight: ${defaults.headingFontWeight};
`;

export const GroupedTabs = styled.div`
  // border-radius: ${defaults.borderRadius};
  // border: 0.75px solid ${colors.border};
`;

export const Disclaimer = styled.div`
  margin-top: 12px;
  font-size: 10px;
  color: #989898;
  text-align: center;
  span {
    color: ${colors.title};
  }
`;
