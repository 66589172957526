import styled from "styled-components";
import { defaults } from "theme";

export const Container = styled.div`
  background-color: #111111;
  max-width: ${defaults.width}px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
