export const SvgArrow = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.776478 9.29076C0.387135 9.68246 0.387135 10.3175 0.776478 10.7092C1.16582 11.1009 1.79707 11.1009 2.18641 10.7092L0.776478 9.29076ZM11.4242 1C11.4242 0.446049 10.9778 -0.00301635 10.4272 -0.00301593L1.45446 -0.00301614C0.903846 -0.00301614 0.457486 0.44605 0.457486 1C0.457486 1.55395 0.903846 2.00302 1.45446 2.00302L9.43025 2.00302L9.43025 10.0271C9.43025 10.5811 9.87661 11.0302 10.4272 11.0302C10.9778 11.0302 11.4242 10.5811 11.4242 10.0271L11.4242 1ZM2.18641 10.7092L11.1322 1.70924L9.72226 0.290761L0.776478 9.29076L2.18641 10.7092Z"
      fill="white"
    />
  </svg>
);

export const SvgSliderArrow = () => (
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="13"
      cy="13.3358"
      rx="13"
      ry="13.3358"
      transform="rotate(-180 13 13.3358)"
      fill="#3F5DDC"
      fill-opacity="0.35"
    />
    <path
      d="M15 9.67163L11.25 13.4216L15 17.1716L14.25 18.6716L9 13.4216L14.25 8.17163L15 9.67163Z"
      fill="white"
    />
  </svg>
);
