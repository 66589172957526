import styled from "styled-components";
import { defaults, colors } from "../../theme";

export const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(1fr, 1fr);
  column-gap: 10px;
  row-gap: 15px;
`;

export const ButtonGridItem = styled.button<{
  $isActive: boolean;
  $hasLabel?: boolean;
  $hasBg?: boolean;
  $removeBorder?: boolean;
}>`
  font-family: ${defaults.fontFamily};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  color: ${colors.btnText};
  text-decoration: none;
  text-align: center;
  background-color: ${(props) => (props.$hasBg ? colors.btn : "transparent")};
  border-width: ${(props) => (props.$removeBorder ? "0" : "1px")};
  border-style: solid;
  border-color: ${(props) => (props.$isActive ? "#fff" : "rgb(75, 75, 75)")};
  width: 100%;
  min-height: 48px;
  border-radius: ${defaults.smallBorderRadius};
  position: relative;
  padding: 10px 12px;
  outline: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: ${defaults.transitionFull};

  &:focus {
    box-shadow: ${defaults.focusBoxShadow};
  }

  &:hover {
    color: ${colors.btnHoverText};
    background-color: ${(props) =>
      props.$hasBg ? colors.btnHover : "rgba(255, 255, 255, 0.05)"};
  }

  span {
    font-size: 11px;
    line-height: 1.4;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 2px;
    margin-top: -2px;
  }
`;
