import React from "react";
import { LoadingResponse } from "pages/GiftSuggestions/V2/types";
import { Loader } from "components/Loader";
import { StyledButton } from "./style";
import { SvgArrow } from "svg/SvgArrow";

export type ButtonColors = "blue" | "red" | "greyOutline";
export type ButtonIcons = "search" | "arrow";
export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: ButtonColors;
  icon?: ButtonIcons;
  isLoading?: boolean;
  loadingData?: LoadingResponse | null;
};

// function SearchIcon() {
//   return <span className="material-symbols-outlined">&#xe8b6;</span>;
// }

export const Button = ({
  color = "blue",
  type = "button",
  children,
  icon,
  isLoading,
  loadingData,
  ...rest
}: ButtonProps) => {
  return (
    <StyledButton $color={color} type={type} {...rest}>
      {loadingData ? (
        <>
          <Loader
            variant="logo"
            size="small"
            loaderText={loadingData?.message ? `   ${loadingData.message}` : ""}
          />
          {/* {loadingData?.message ? `   ${loadingData.message}` : ""} */}
        </>
      ) : (
        <>
          {/* icon === "search" ? (
            <SearchIcon />
          ) : **/}
          {icon === "arrow" ? <SvgArrow /> : null}
          {children}
        </>
      )}
    </StyledButton>
  );
};

export { ButtonContainer } from "./style";
