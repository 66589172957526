import { SvgStar } from "svg/SvgStar";
import { StarRatingWrapper } from "./style";

interface StarRatingProps {
  rating: number;
}

export const StarRating = ({ rating }: StarRatingProps) => {
  return (
    <StarRatingWrapper aria-label={`${rating} out of 5`}>
      {[...Array(5)].map((_, index) => {
        const isFilled = index < Math.floor(rating);

        return (
          <span key={index} className={index < rating ? "filled" : "empty"}>
            <SvgStar fill={isFilled} />
          </span>
        );
      })}
    </StarRatingWrapper>
  );
};
