import styled from "styled-components";

export const MenuIconContainer = styled.div`
  cursor: pointer;
`;

export const MenuList = styled.ul`
  padding: 100px 30px 0;
  margin: 0;
  list-style-type: none;
`;

export const MenuItem = styled.li`
  padding-bottom: 1rem;
  a {
    font-size: 1rem;
    font-weight: 500;
    color: #dedede;
    text-decoration: none;
    &:hover {
      color: #fff;
    }
  }
`;

export const SecondaryMenuList = styled(MenuList)`
  padding: 40px 30px 0;
`;

export const SecondaryMenuItem = styled(MenuItem)`
  padding-bottom: 0.8rem;
  a {
    font-size: 0.9rem;
    font-weight: 400;
  }
`;
export const MenuFooter = styled.div`
  border-top: 3px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    90deg,
    rgb(242, 172, 132) 0%,
    rgb(63, 89, 228) 52.6%,
    rgb(64, 22, 160) 100%
  );
  padding: 40px 30px 30px;
  margin-top: 40px;
`;
