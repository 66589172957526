import styled from "styled-components";
import { defaults, colors } from "theme";
import { FormStyleProps } from "../types";
import { formElementBaseStyle, inputHeight } from "../style";

const controlsWidth = 80;

export const InputNumberWrapper = styled.div<FormStyleProps>`
  .rc-input-number {
    position: relative;
  }

  .rc-input-number-input {
    ${formElementBaseStyle};
    padding-left: 20px;
    padding-right: ${controlsWidth}px;
    /* Hide the up/down arrows for input type=number */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    .rc-input-number-input-wrap {
      width: 100%;
    }
  }

  // .rc-input-number-handler-wrap {
  //   display: flex;
  //   flex-direction: column;
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  // }

  // .rc-input-number-handler {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   width: ${controlsWidth}px;
  //   height: ${inputHeight / 2}px;
  //   border: 1px solid ${colors.border};
  //   cursor: pointer;

  //   &:hover {
  //     transition: ${defaults.transitionFull};
  //     border-color: #fff;
  //   }
  // }

  // .rc-input-number-handler-up {
  //   border-top-right-radius: ${defaults.borderRadius};
  //   border-top-color: transparent;
  //   border-right-color: transparent;
  //   border-bottom-color: transparent;
  // }

  // .rc-input-number-handler-down {
  //   border-bottom-right-radius: ${defaults.borderRadius};
  //   border-bottom-color: transparent;
  //   border-right-color: transparent;
  // }

  // .increment-icon {
  //   font-variation-settings: "OPSZ" 20, "wght" 200;
  //   width: 20px;
  //   height: 20px;
  // }
`;
export const InputClearBtn = styled.button`
  position: absolute;
  top: 10px;
  right: 46px;
  background: none;
  border: none;
  padding: 0;
  outline: 0;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${defaults.borderRadius}; // focus
  transition: color ${defaults.transition};
  color: #fff;

  span {
    font-variation-settings: "OPSZ" 20, "wght" 200;
    display: block;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
  }
`;

export const DollarSymbol = styled.span`
  position: absolute;
  left: 10px;
  top: 10px;
  pointer-events: none;
  color: #fff;
  font-size: 16px;
`;
