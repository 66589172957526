import { ReactStyle } from "types/react";
import { LoaderSpinner } from "./LoaderSpinner";
import { LogoSpinner } from "./LogoSpinner";
import { LoaderContainer } from "./style";

export type LoaderProps = {
  variant?: "inline" | "block" | "absolute" | "fixed" | "fixedCenter" | "logo";
  backdrop?: "none" | "transparent" | "opaque";
  size?: "small" | "large";
  style?: ReactStyle;
  loaderText?: string;
};

export const Loader = ({
  variant = "inline",
  backdrop = "none",
  size = "large",
  loaderText,
  ...rest
}: LoaderProps) => {
  return (
    <LoaderContainer
      $variant={variant}
      $backdrop={backdrop}
      $size={size}
      aria-busy="true"
      aria-live="polite"
      className="loader"
      {...rest}
    >
      {variant === "logo" ? (
        <LogoSpinner text={loaderText} />
      ) : (
        <LoaderSpinner size={size} />
      )}
    </LoaderContainer>
  );
};
