import { SvgModalClose } from "svg/SvgModalClose";
import { ModalClose as ModalCloseContainer } from "./style";

export type ModalProps = {
  position?: "left" | "right";
  onClick: () => void;
};

export function ModalClose({ position, onClick }: ModalProps) {
  return (
    <ModalCloseContainer
      position={position}
      onClick={onClick}
      aria-label="Close"
      tabIndex={-1}
    >
      <SvgModalClose />
    </ModalCloseContainer>
  );
}
