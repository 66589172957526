import styled from "styled-components";
import { FormStyleProps } from "../types";
import { formElementBaseStyle, formElementStyle } from "../style";

export const StyledTextarea = styled.textarea<FormStyleProps>`
  ${formElementBaseStyle};
  font-size: ${formElementStyle.textareaFontSize};
  line-height: ${formElementStyle.textareaLineHeight};
  padding-top: 10px;
  padding-bottom: 10px;
  height: ${formElementStyle.textareaHeight};
  resize: none;
  min-height: ${formElementStyle.textareaHeight};
  max-height: ${formElementStyle.textareaMaxHeight};
`;
