import { SvgLogo } from "svg/SvgLogo";
import { Container, LogoContainer, Version } from "./style";
import { appVersion } from "utils/constants";
import { Menu } from "./Menu";

export function Header() {
  return (
    <Container>
      <Menu />
      <LogoContainer>
        <SvgLogo />
        <Version>{appVersion}</Version>
      </LogoContainer>
      <></>
    </Container>
  );
}
