import { useState } from "react";
import RcInputNumber from "rc-input-number";
import { FormProps } from "../types";
import { getFieldError } from "utils/form";
import { FormGroup } from "../FormGroup";
import { FormLabel } from "../FormLabel";
import { FormError } from "../FormError";
import { DollarSymbol, InputClearBtn, InputNumberWrapper } from "./style";

type InputNumberProps = React.InputHTMLAttributes<HTMLInputElement> &
  FormProps & {
    min?: string;
  };

// const IncrementUp = () => {
//   return (
//     <span className="increment-icon material-symbols-outlined">&#xe5ce;</span>
//   );
// };

// const IncrementDown = () => {
//   return (
//     <span className="increment-icon material-symbols-outlined">&#xe5cf;</span>
//   );
// };

export const InputNumber = ({
  id,
  label,
  isRequired,
  isLoading,
  min,
  validationTheme,
  containerStyle,
  field,
  form,
}: InputNumberProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const fieldError = getFieldError({
    form,
    field,
  });

  const getHasError = () => {
    return !!fieldError;
  };
  const hasError = getHasError();

  function handleOnChange(value: string | number | null) {
    if (value !== null) {
      form.setFieldValue(
        field.name,
        typeof value === "number" ? value.toString() : value
      );
    }
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (field.value === "") {
      setIsFocused(false);
    }
  };

  const renderClearBtn = () => {
    if (!field.value) return null;
    return (
      <InputClearBtn
        type="button"
        onClick={() => form.setFieldValue(field.name, "")}
        tabIndex={-1}
        aria-label={`Clear${label ? ` ${label}` : ""}`}
      >
        <span className="material-symbols-outlined">&#xe5cd;</span>
      </InputClearBtn>
    );
  };

  return (
    <FormGroup
      isLoading={isLoading}
      style={{
        ...containerStyle,
      }}
    >
      {label && (
        <FormLabel
          htmlFor={id}
          isRequired={isRequired}
          className={isFocused ? "focused" : ""}
        >
          {label}
        </FormLabel>
      )}
      <InputNumberWrapper>
        {isFocused ? <DollarSymbol>$</DollarSymbol> : null}
        <RcInputNumber
          id={id}
          value={field.value}
          onChange={handleOnChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type="number"
          // upHandler={<IncrementUp />}
          // downHandler={<IncrementDown />}
          min={min}
          className={hasError ? "has-error" : ""}
        />
        {renderClearBtn()}
      </InputNumberWrapper>
      {fieldError && (
        <FormError validationTheme={validationTheme}>{fieldError}</FormError>
      )}
    </FormGroup>
  );
};
