export const defaults = {
  fontFamily: "'Inter', sans-serif",
  fontSize: "16px",
  headingSize: "18px",
  headingFontWeight: 600,
  lineHeight: 1.8,
  fontWeight: 400,
  smallBorderRadius: "2px",
  borderWeight: "0.75px",
  borderRadius: "6px",
  containerWidth: 1180,
  transition: "150ms",
  transitionFull: "all 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s",
  padding: "16px",
  containerPadding: "30px",
  margin: "15px",
  focusBoxShadow: "0 0 0 2px #5151C8",
  width: 400,
};
