type Props = {
  fill?: boolean;
};
export const SvgStar = ({ fill }: Props) => (
  <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m7.238 2.357 1.209 3.718.112.346h4.273L9.67 8.719l-.294.214.112.345 1.209 3.72-3.164-2.3-.294-.213-.294.214-3.163 2.298 1.208-3.719.113-.345-.294-.214-3.164-2.298h4.274l.112-.346 1.208-3.718Z"
      stroke="#F87329"
      fill={fill ? "#F87329" : "#1D1D1F"}
    />
  </svg>
);
