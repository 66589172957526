import { css } from "styled-components";
import { FormStyleProps } from "./types";
import { defaults, colors } from "../../theme";

const paddingVertical = "0";
const paddingHorizontal = "12px";

export const inputHeight = 48;

export const formElementStyle = {
  fontFamily: defaults.fontFamily,
  fontSize: "16px",
  fontWeight: "500",
  color: "#fff",
  height: `${inputHeight}px`,
  textareaFontSize: "14px",
  textareaHeight: "88px",
  textareaMaxHeight: "400px",
  textareaLineHeight: "1.4",
  borderStyle: "solid",
  padding: `${paddingVertical} ${paddingHorizontal}`,
  paddingVertical,
  paddingHorizontal,
  borderWeight: defaults.borderWeight,
  borderRadius: defaults.borderRadius,
  placeholderColor: "#B0B0B0",
  iconColor: "#6E6D7D",
  labelColor: colors.formLabel,
  labelFontSize: "14px",
  labelFontWeight: "400",
  labelLineHeight: "1.8",
  labelMargin: "0 0 4px",
  formGroupMargin: "0 0 12px",
  boxShadow: "0 0 0 4px",
  transition: defaults.transition,

  // Default state
  borderColor: colors.border,
  backgroundColor: "transparent",

  // Hover state
  borderColorHover: "#fff",
  boxShadowColorHover: "rgba(219, 219, 219, 0.5)",

  // Focus state
  borderColorFocus: "#fff",
  boxShadowColorFocus: "rgba(255, 255, 255, 0.2)",

  // Disabled state
  borderColorDisabled: colors.border,
  backgroundColorDisabled: "transparent",

  // Error state
  borderColorError: colors.red,
  boxShadowColorError: "rgba(252, 34, 34, 0.25)",
};

export const formElementBaseStyle = css<FormStyleProps>`
  font-family: ${defaults.fontFamily};
  font-size: ${formElementStyle.fontSize};
  font-weight: ${formElementStyle.fontWeight};
  color: ${formElementStyle.color};
  background-color: ${formElementStyle.backgroundColor};
  border-radius: ${formElementStyle.borderRadius};
  border-width: ${formElementStyle.borderWeight};
  border-style: ${formElementStyle.borderStyle};
  border-color: ${(props) =>
    (props.$hasError && formElementStyle.borderColorError) ||
    formElementStyle.borderColor};
  height: ${(props) =>
    (props.$theme === "displayField" && "36px") || formElementStyle.height};
  padding: ${formElementStyle.padding};
  width: 100%;
  outline: 0;
  display: block;
  transition: all ${defaults.transition};

  &:hover {
    border-color: ${(props) =>
      (props.$hasError && formElementStyle.borderColorError) ||
      formElementStyle.borderColorHover};
  }

  &:focus {
    border-color: ${(props) =>
      (props.$hasError && formElementStyle.borderColorError) ||
      formElementStyle.borderColorFocus};
    box-shadow: ${formElementStyle.boxShadow}
      ${formElementStyle.boxShadowColorFocus};
  }

  &:read-only {
    // Note: use read-only to trigger likes/dislikes modals
    cursor: pointer;
    border-color: ${formElementStyle.borderColor};

    &:hover {
      border-color: ${formElementStyle.borderColorHover};
    }

    &:focus {
      border-color: ${formElementStyle.borderColorFocus};
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${formElementStyle.backgroundColorDisabled};
    border-color: ${formElementStyle.borderColorDisabled};
    box-shadow: none;
  }

  &::placeholder {
    color: ${formElementStyle.placeholderColor};
  }
`;
