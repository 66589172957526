import { LogoContainer, FlexDiv } from "./style";
import { LoaderSpinner } from "../LoaderSpinner";

interface LogoSpinnerProps {
  text?: string;
}
export const LogoSpinner = ({ text }: LogoSpinnerProps) => {
  return (
    <LogoContainer>
      <img src="/logo-loader.gif" alt="logo-spinner" />
      {text && (
        <FlexDiv>
          <LoaderSpinner size="small" />
          {text}
        </FlexDiv>
      )}
    </LogoContainer>
  );
};
