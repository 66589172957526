import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./style.css";
import { ButtonLink } from "components/ButtonLink";
import { Img } from "components/Img";
import { Feedback } from "components/Feedback";
import {
  GiftSuggestionResultsResponse,
  GiftSuggestionResultsWithId,
} from "../types";
import {
  Container,
  Heading,
  Result,
  ResultHeading,
  ResultGroupHeading,
  ResultTag,
  AffiliateTag,
  ResultText,
  ResultImgContainer,
  ResultCtaHeading,
  ResultsFound,
  BackToTop,
  Wrapper,
  OptionHolder,
  ReviewContainer,
} from "./style";
import { Props } from "./types";
import { SvgArrow } from "svg/SvgArrow";
import { Separator } from "components/Separator";
import { StarRating } from "components/StarRating";

export function Results({
  giftSuggestions,
  sendGiftBuyNowNotification,
}: Props) {
  function getDeliveryValue(
    delivery: GiftSuggestionResultsResponse["delivery"]
  ) {
    if (typeof delivery === "string") {
      return delivery === "Free" || delivery === "Unknown"
        ? delivery
        : `$${delivery}`;
    } else if (typeof delivery === "number") {
      return `$${delivery}`;
    } else {
      return "";
    }
  }

  function onClickBuyNow({ title, link }: { title: string; link: string }) {
    console.log("onClickBuyNow");
    sendGiftBuyNowNotification({ title, link });
  }

  function renderResultItem({
    result,
    resultNum,
    currentIndex,
    resultsTotalNum,
    group,
  }: {
    result: GiftSuggestionResultsWithId;
    resultNum: number;
    currentIndex: number;
    resultsTotalNum: number;
    group?: string;
  }) {
    const title = result.title ?? "";
    const seller = result.seller;
    const description = result.description;
    const advert = result.advert;
    const link = result.link ?? "";
    const thumbnail = result.thumbnail;
    const price = result.price;
    const delivery = result.delivery;
    const rating = result.rating;
    const reviews = result.reviews;

    return (
      <>
        <ResultGroupHeading>{group}</ResultGroupHeading>
        <SwiperSlide key={result.id}>
          <Container>
            <OptionHolder>
              {resultsTotalNum > 1
                ? `Option ${resultNum}/${resultsTotalNum}`
                : ""}
            </OptionHolder>
            {thumbnail ? (
              <ResultImgContainer>
                <Img src={thumbnail} alt={title} />
              </ResultImgContainer>
            ) : null}
            {/* <ResultGroupHeading>{group}</ResultGroupHeading> */}
            <ResultHeading>{title}</ResultHeading>
            {typeof price === "number" || delivery ? (
              <>
                {typeof price === "number" ? (
                  <ResultCtaHeading>
                    <span>AU</span> ${price.toString()}
                  </ResultCtaHeading>
                ) : null}
                {delivery ? (
                  <ResultCtaHeading>
                    <span>Delivery:</span> {getDeliveryValue(delivery)}
                  </ResultCtaHeading>
                ) : null}
              </>
            ) : null}
            <ReviewContainer>
              {rating ? (
                <>
                  {rating} <StarRating rating={rating} /> (
                  {reviews ?? 0 >= 1000
                    ? `${((reviews ?? 0) / 1000).toFixed(0)}k`
                    : reviews ?? 0}
                  )
                </>
              ) : (
                "No reviews yet"
              )}
            </ReviewContainer>
            <ResultTag>
              {advert && <AffiliateTag>affiliate</AffiliateTag>}
              Seller: <span>{seller}</span>
            </ResultTag>
            {description ? <ResultText>{description}</ResultText> : null}
            {link ? (
              <ButtonLink
                href={link}
                onClick={() =>
                  onClickBuyNow({
                    title,
                    link,
                  })
                }
                target="_blank"
              >
                View now <SvgArrow />
              </ButtonLink>
            ) : null}
          </Container>
        </SwiperSlide>
        <Separator />
      </>
    );
  }

  if (giftSuggestions.length) {
    const totalOptions = giftSuggestions.reduce((acc, curr) => {
      return acc + (curr.results?.length ?? 0);
    }, 0);
    return (
      <>
        <ResultsFound key="resultsTitle">
          <Heading className="center">Gift ideas</Heading>
          We created <span>{giftSuggestions.length}</span> gift ideas with{" "}
          <span>{totalOptions}</span> options
        </ResultsFound>
        {giftSuggestions.map((giftSuggestion, index) => (
          <React.Fragment key={giftSuggestion.id}>
            <Result key={giftSuggestion.id}>
              <Swiper
                navigation={true}
                modules={[Navigation]}
                autoHeight={true}
                slidesPerView={1.2}
                spaceBetween={3}
              >
                {giftSuggestion.results?.map((result, i) =>
                  renderResultItem({
                    result,
                    resultNum: i + 1,
                    currentIndex: index,
                    resultsTotalNum: giftSuggestion.results?.length ?? 0,
                    group: giftSuggestion.group,
                  })
                )}
              </Swiper>
            </Result>
          </React.Fragment>
        ))}
        <Wrapper>
          <Feedback />
        </Wrapper>
        <BackToTop href="#top">Back to top</BackToTop>
      </>
    );
  } else {
    return null;
  }
}
