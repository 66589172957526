export const colors = {
  rootBg: "#181818",
  bg: "rgb(17, 17, 17)",
  field: "rgb(29, 29, 31)",
  btn: "rgb(63, 89, 228)",
  btnHover: "rgb(52, 73, 186)",
  btnText: "rgb(255, 255, 255)",
  btnHoverText: "rgb(255, 255, 255)",
  title: "rgb(233, 233, 233)",
  text: "rgb(250, 250, 250)",
  textLight: "rgba(222, 222, 222, 1)",
  toolTip: "rgba(217, 217, 217, 1)",
  formLabel: "rgb(177, 177, 177)",
  border: "rgb(61, 64, 74)",
  green: "#ADD110",
  orange: "#FF902A",
  red: "#FC2222",
  listHover: "rgba(0, 0, 0, 0.2)",
  blue: "rgb(63, 89, 228)",
  blueHover: "rgb(52, 73, 186)",
};
