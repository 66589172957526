import { createGlobalStyle } from "styled-components";
import { defaults } from "./defaults";
import { colors } from "./colors";
import "./modal.css";

export const ThemeDefaults = createGlobalStyle`
  * {
    box-sizing: border-box;

    &::selection {
      color: #fff;
      background-color: ${colors.blue};
    }
  }

  html {
    background-color: ${colors.rootBg};
    font-family: ${defaults.fontFamily};
    font-size: ${defaults.fontSize};
    line-height: ${defaults.lineHeight};
    font-weight: ${defaults.fontWeight};
    color: ${colors.text};
    -webkit-font-smoothing: antialiased;
  }

  body {
    // padding: 0 15px;
  }
    #root {
    min-height: 100vh;

  html > body {
    overflow: auto;
  }

  svg {
    display: block;
  }
`;

export { defaults } from "./defaults";
export { colors } from "./colors";
