import styled from "styled-components";
import { defaults } from "theme";
import { FormStyleProps } from "../types";
import { formElementBaseStyle, formElementStyle } from "../style";

export const StyledInput = styled.input<
  FormStyleProps & { $isClearable?: boolean }
>`
  ${formElementBaseStyle};
  padding-right: ${(props) =>
    (props.$isClearable && "30px") || formElementStyle.paddingHorizontal};
    &[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      display: none;
    }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const InputClearBtn = styled.button`
  position: absolute;
  right: 46px;
  background: none;
  border: none;
  padding: 0;
  outline: 0;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${defaults.borderRadius}; // focus
  transition: color ${defaults.transition};
  color: #fff;

  span {
    font-variation-settings: "OPSZ" 20, "wght" 200;
    display: block;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
  }
`;
