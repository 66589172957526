export const MenuIcon = () => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="1.3" x2="24" y2="1.3" stroke="white" strokeWidth="1.4" />
      <line y1="9.3" x2="24" y2="9.3" stroke="white" strokeWidth="1.4" />
      <line y1="17.3" x2="24" y2="17.3" stroke="white" strokeWidth="1.4" />
    </svg>
  );
};
